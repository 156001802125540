.cmp-cc-slideshow {
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}

.cmp-cc-slideshow__slide {
  list-style: none;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: opacity map-get($transitions, default-ease);

  & img {
    width: 100%;
  }
}

// FINAL ACTIVE SLIDE CLASS
.jsa-isActive.cmp-cc-slideshow__slide {
  opacity: 1;
  pointer-events: auto;
  position: static;
}
