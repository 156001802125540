$verticalSpacer: rem(70px);

//
// CONTENT CAROUSEL
// ------------------------------

.contentcarousel {
  max-width: 100%;
}

.cmp-content-carousel-bleed,
.light-background .cmp-content-carousel-bleed {
  background-color: map-get($backgrounds, light-bg);
  @include fullbleed();
}

.sepia-background .cmp-content-carousel-bleed {
  background-color: map-get($backgrounds, sepia-bg);
  @include fullbleed();
}

.cmp-content-carousel-wrap {
  position: relative;
  z-index: map-get($z, base);
}

.cmp-content-carousel__blocks {
  padding: $verticalSpacer 0;

  .cmp-content-carousel__blocks-eyebrow-hdg {
    @include ui-overline2;
    // Override font-size, as the mixin above is used in multiple places
    font-size: rem(12px);
    margin-bottom: rem(17px);
    text-transform: uppercase;
  }

  .cmp-content-carousel__blocks-title-hdg {
    font-size: rem(36px);
    font-weight: map-get($font-weights, light);
    letter-spacing: -0.78px;
    line-height: 1.17;
    margin-bottom: rem(20px);
  }
}

.cmp-content-carousel__blocks-slideshow-stack,
.cmp-content-carousel__blocks-slideshow-stack-section {
  display: block;
}

// slideshow styles with transition states
@import './cc_slideshow';

// slideshow text content layout
@import './cc_content_layout';

// carousel actions - clickable next, previous, and tab buttons
@import './cc_carousel_actions';
