//
// NEXT & PREVIOUS (clickable)
// ------------------------------

.cmp-carousel-prev,
.cmp-carousel-next {
  background-color: transparent;
  border: 1px solid map-get($borders, dark-border);
  border-radius: 100%;
  box-sizing: border-box;
  color: map-get($colors, dark-text);
  cursor: pointer;
  display: inline-block;
  font-size: 0;
  height: rem(60px);
  overflow: hidden;
  position: relative;
  width: rem(60px);
}

.cmp-carousel-next {
  margin-left: rem(18px);
}

.cmp-carousel-prev .sw-icon,
.cmp-carousel-next .sw-icon {
  height: rem(20px);
  left: 50%;
  position: absolute;
  color: map-get($colors, dark-text);
  top: 50%;
  width: rem(12px);
}

.cmp-carousel-prev .sw-icon {
  // visual center looks better than actual center
  transform: translate(-75%, -50%);
}

.cmp-carousel-next .sw-icon {
  // visual center looks better than actual center
  transform: translate(-35%, -50%);
}

.cmp-carousel-prev:hover,
.cmp-carousel-next:hover {
  background-color: map-get($backgrounds, dark);
  border-color: map-get($borders, dark-border);

  & .sw-icon {
    color: map-get($colors, light-text);
  }
}

.cmp-carousel-prev:disabled,
.cmp-carousel-next:disabled {
  background-color: transparent;
  cursor: default;
  opacity: 0.33;

  & .sw-icon {
    color: map-get($colors, dark-text);
  }
}

//
// PAGINATION TABS
// ------------------------------

.cmp-cc-pagination-tabs {
  margin: rem(20px 0);
  position: relative;
  overflow: hidden;

  @include media('>=desktop') {
    overflow: visible;
  }

  // fade overlays
  // - hides overflow content with light fade
  &::before,
  &::after {
    bottom: 0;
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
  }

  &::before {
    right: 100%; // moves fade overlay to the LEFT gutter
    width: 50vw;
    z-index: map-get($z, base);
  }

  &::after {
    left: 70%; // moves fade overlay to the RIGHT gutter
    width: 50vw;
    z-index: map-get($z, base);
  }
}

.cmp-cc-pagination-tabs,
.light-background .cmp-cc-pagination-tabs {
  &::before {
    background: linear-gradient(
      90deg,
      rgba(map-get($backgrounds, light-bg), 1) 80%,
      rgba(map-get($backgrounds, light-bg), 0) 100%
    );
  }

  &::after {
    background: linear-gradient(
      90deg,
      rgba(map-get($backgrounds, light-bg), 0) 0%,
      rgba(map-get($backgrounds, light-bg), 1) 50%
    );
  }
}

.sepia-background .cmp-cc-pagination-tabs {
  &::before {
    background: linear-gradient(
      90deg,
      rgba(map-get($backgrounds, sepia-bg), 1) 80%,
      rgba(map-get($backgrounds, sepia-bg), 0) 100%
    );
  }

  &::after {
    background: linear-gradient(
      90deg,
      rgba(map-get($backgrounds, sepia-bg), 0) 0%,
      rgba(map-get($backgrounds, sepia-bg), 1) 50%
    );
  }
}

// this element is dynamically added with JS
.cmp-cc-pagination-tabs__indicator {
  background-color: map-get($backgrounds, dark);
  border-radius: 2px;
  bottom: 0;
  display: block;
  height: 2px;
  left: 0;
  pointer-events: none;
  position: absolute;
  transition: all map-get($transitions, default-ease);
  width: 0;
}

.cmp-cc-pagination-tabs__list {
  margin: 0;
  padding: 0;
  position: relative;
  transition: all map-get($transitions, half-second-ease);
  white-space: nowrap;
}

.cmp-cc-pagination-tabs__list-item {
  display: inline-block;
  list-style: none;
  margin: rem(0 36px 0 0);
  padding: 0;
}

//
// INDIVIDUAL CLICKABLE PAGER ACTION
// ------------------------------

.cmp-pager-action {
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  display: block;
  overflow: hidden;
  padding: rem(12px 0 14px);

  // text styling inheriting from global
  text-decoration: none;
  text-transform: uppercase;

  @include ui-overline2;
}

.cmp-pager-action:focus {
  border-radius: 3px;
  box-shadow: none;
  outline: 1px solid map-get($colors, focus-outline);
}

.cmp-pager-action:hover {
  background-color: map-get($backgrounds, action-hover-light);
}
