.cmp-cc-col-layout {
  $spacing: 15px;

  display: flex;
  flex-wrap: wrap;
  margin: rem(0 ($spacing * -1));

  & > [class*='cmp-cc-col-layout__col'] {
    box-sizing: border-box;
    padding: rem(0 $spacing $spacing);
    width: 100%;
  }

  @include media('>=desktop') {
    @for $i from 1 through 12 {
      & > .cmp-cc-col-layout__col-#{$i}-12 {
        width: ($i / 12 * 100%);
      }
    }

    & > .cmp-cc-col-layout__col-full {
      width: 100%;
    }

    & > .cmp-cc-col-layout__md-order-last {
      order: 5;
      text-align: right; // for button alignment
    }
  }
}

// TEXT CONTENT

.cmp-cc-main-text {
  font-size: rem(16px);
  font-weight: map-get($font-weights, normal);
  line-height: rem(20px);
  letter-spacing: -0.25px;

  @include media('>=desktop') {
    line-height: 1.5;
    letter-spacing: -0.35px;

    &.cmp-cc-main-text-sm {
      @include ui-heading3;

      font-size: rem(14px);
    }
  }
}

// BULLET LIST

.cmp-cc-bullet-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cmp-cc-bullet-list > li {
  font-weight: map-get($font-weights, normal);
  padding: 0 0 0 rem(12px);
  position: relative;
}

.cmp-cc-bullet-list > li + li {
  margin-top: rem(9px);
}

.cmp-cc-bullet-list > li::before {
  content: '•';
  font-size: 0.9em;
  font-weight: map-get($font-weights, normal);
  left: 0;
  position: absolute;
  top: 0;
}
